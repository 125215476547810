/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Text
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: #6a5988 !important;
}

.text-theme-secondary {
  color: #e78c04 !important;
}

.text-secondary {
  color: #646d75 !important;
}

/* ----------------------------------------------------------------------------
    Backgrounds
----------------------------------------------------------------------------- */
.bg-theme-primary {
  background-color: #6a5988 !important;
}

.bg-theme-primary-light {
  background-color: #7c6a9d !important;
}

.bg-theme-primary-gradient {
  background: rgb(106, 89, 136);
  background: linear-gradient(137deg, rgb(155, 109, 85) 0%, #6a5988 100%);
}

.bg-theme-secondary {
  background-color: #e78c04 !important;
}

.bg-theme-light {
  background-color: #f0f0f0;
}

/* ----------------------------------------------------------------------------
    Borders
----------------------------------------------------------------------------- */
.border-theme-primary {
  border-color: #6a5988 !important;
}

.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls],
.tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}
.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}
.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}
.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}
.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
  cursor: grab;
}
.tns-ovh:active {
  cursor: grabbing;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal,
.tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > div,
.tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}
.tns-t-ct {
  width: 2333.3333333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}
.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}
.tns-t-ct > div {
  width: 1.4285714286%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

.tns-nav {
  text-align: center;
  margin: 10px 25px;
}
.tns-nav > [aria-controls] {
  width: 13px;
  height: 13px;
  padding: 0;
  margin: 0 5px;
  border-radius: 50%;
  background: #ddd;
  border: 0;
}
.tns-nav > .tns-nav-active {
  background: #6a5988;
}

.tns-controls {
  text-align: center;
}
.tns-controls > [aria-controls] {
  color: #6a5988;
  width: 35px;
  height: 35px;
  padding: 0;
  margin: 0 8px;
  background: transparent;
  border: 1px solid inherit;
}
.tns-controls > [aria-controls]:hover, .tns-controls > [aria-controls]:active {
  color: #fff;
  background-color: #6a5988;
}
.tns-controls > [aria-controls][disabled] {
  opacity: 0.35;
  cursor: not-allowed;
}
.tns-controls > [aria-controls][disabled]:hover {
  color: #6a5988;
  background-color: transparent;
}
.tns-controls > button {
  border: 1px solid #6a5988;
}

.case-studies .tns-controls {
  text-align: center;
}
.case-studies .tns-controls > [aria-controls] {
  color: #e78c04;
  width: 45px;
  height: 45px;
  padding: 0;
  margin: 0 8px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid inherit;
}
.case-studies .tns-controls > [aria-controls]:hover, .case-studies .tns-controls > [aria-controls]:active {
  color: #6a5988;
  background-color: #e78c04;
}
.case-studies .tns-controls > [aria-controls][disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.case-studies .tns-controls > [aria-controls][disabled]:hover {
  color: #e78c04;
  background-color: transparent;
}
.case-studies .tns-controls > button {
  border: 1px solid #e78c04;
}

.pillars-slider-wrap .tns-ovh {
  position: relative;
}
.pillars-slider-wrap .tns-ovh::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(125, 185, 232, 0) 0%, rgb(214, 246, 149) 98%, rgb(216, 247, 147) 100%);
}
.pillars-slider-wrap .tns-nav {
  text-align: center;
  margin: 30px 0 10px 0;
}
.pillars-slider-wrap .tns-nav > [aria-controls] {
  width: 15px;
  height: 15px;
  padding: 0;
  margin: 0 5px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #6a5988;
}
.pillars-slider-wrap .tns-nav > .tns-nav-active {
  background: #6a5988;
}

@media (min-width: 992px) {
  .pillars-slider-wrap .tns-nav {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
  }
  .pillars-slider-wrap .tns-nav > [aria-controls] {
    display: block;
    margin: 8px 0;
  }
}